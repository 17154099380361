
form{
    margin-top: 9rem;
    flex-direction: column;
    width: 400px;
    height: 300px;
    border:1px solid rgb(186, 183, 183);
    border-radius: 5%;
    box-shadow: 2px 3px 5px rgb(137, 137, 137);
    margin-top: 15rem;
    margin-left: 35rem;
   

}
input{
    margin-top: 40px;
    margin-left: 10px;
}
span{
    margin-top: 40px;
    margin-left: 10px;
    font-size: 20px;
}
button{
    margin-top: 2.5rem;
    margin-left: 9rem;
    width: 100px;
    height: 40px;
    background-color: rgb(235, 234, 234);
    color: rgb(8, 8, 8);
    border-color: rgb(252, 249, 249);
    border-radius: 10%;
    font-size: 1.2rem;

    
}